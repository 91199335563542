import React from 'react';
import { Link, navigate } from 'gatsby'
import Layout from "../../components/layout"
import { connect } from "react-redux"
import SEO from "../../components/seo"
import { trim, split, last, isEmpty, head, has, isNil, truncate, capitalize, every, reject } from "lodash"
import { getJobDetail } from "../../actions/jobs"
import {  displayHumanDate, displayDate, makeArrayKeyLabel, checkUserCV } from "../../utils/helpers"
import UserContext from "../../context/CurrentUserContext"
import ContentDetailLoader from "../../components/Loader/ContentDetailLoader"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { applyJob } from "../../actions/jobs"
import classnames from "classnames"
import { FaMapMarker, FaHourglassHalf } from "react-icons/fa"

const MySwal = withReactContent(Swal)

class JobDetail extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        windowWidth:null,
        id:null,
        loaded:false,
        loading:false,
        item:null,
        error:''
      }

      this.mediaQuery = {
        desktop: 1200,
        tablet: 768,
        phone: 576,
      };
    }

    componentDidMount() {
      const { location } = this.props
      const pathArr = split(trim(location.pathname, '/'), '/')
      const pathEnd = last(pathArr)
      const detailId = split(pathEnd, '_')
      const jobId = detailId.length > 1 ? head(detailId) : null

      this.setState({
        id:jobId,
        loading:true,
        windowWidth: document.body.clientWidth,
      }, ()=> {
        getJobDetail({
          id:jobId
        }).then(res=>{
          this.setState({
            loaded:true,
            loading:false,
            item:res.data
          })
        }).catch(err=>{
          if( isNil(err.response) ) {
            this.setState({
              loading:false,
              loaded:true,
              error:'Error Internet Connection'
            })
          } else {
            if( err.response.status == 200 ) {
              this.setState({
                loading:false,
                loaded:true,
              })
            } else {
              this.setState({
                loaded:true,
                loading:false,
                error:has(err.response, 'data.messages') ? err.response.data.messages : 'Something went wrong from server connection'
              })
            }
          }
          
        })
      })
      
    }

    onJustApply(user, e) {
      if( ! isEmpty(user) ) {
        MySwal.fire({
          title: <p>Are you sure?</p>,
          text:'Once submitted! You won\'t be able to cancel this',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Submit',
          showLoaderOnConfirm: true,
          onBeforeOpen:() => {
            let check = checkUserCV(this.props.auth.user)
            if( ! isEmpty(check) ) {
              let a = []
              check.map((it,i) => {
                let b = it.key.replace("jobseekers_", '')
                b = b.replace("_", ' ')
                a.push(capitalize(b))
              })

              return MySwal.fire({
                title:"Check your biodata or CV before apply this job",
                html:<p className="alert alert-warning"> Sorry! Your data is not complete! Please complete your biodata/upload latest resume/work experiences/educations/skill and languages </p>
              })
            }
          },
          onOpen: () => {
            // `MySwal` is a subclass of `Swal`
            //   with all the same instance & static methods
            //MySwal.clickConfirm()
          }
        }).then((obj) => {
          if( has(obj, 'dismiss') ) {
            return
          }

          MySwal.fire({
            title: 'Now applying...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            onOpen: () => {
              MySwal.showLoading();
              applyJob({
                job_id:this.state.item.job_id,
                description: obj.value
              }).then(res=>{
                  MySwal.fire(
                    'Great!',
                    'This job has been applied. Please check out in your dasboard',
                    'success'
                  )
                  setTimeout(()=>{
                    navigate('/user/dashboard')
                  }, 4000)
              }).catch(err=>{
                MySwal.fire(
                  'Sorry!',
                  has(err.response.data, 'messages') ? err.response.data.messages : 'Something unknown error!',
                  'error'
                )
              })
            }
          })
          
        })
      } else {
        MySwal.fire({
          title: 'Please login first...',
          text:'Please wait! it will auto redirect to login page in any seconds',
          timer: 2000,
          onBeforeOpen: () => {
            MySwal.showLoading()
          },
          onClose: () => {
            navigate('/login')
          }
        })
      }
      
    }

    onApply(user, e) {
      if( ! isEmpty(user) ) {
        MySwal.fire({
          title: <p>Enter Message</p>,
          text:'Brief a message or a reason why you apply this job.',
          input: 'textarea',
          inputAttributes: {
            autocapitalize: 'off',
            placeholder:"e.g. I would you like to apply this position because I am experienced IT developer for 10 years...."
          },
          showCancelButton: true,
          confirmButtonText: 'Submit',
          showLoaderOnConfirm: true,
          onBeforeOpen:() => {
            let check = checkUserCV(this.props.auth.user)
            if( ! isEmpty(check) ) {
              let a = []
              check.map((it,i) => {
                let b = it.key.replace("jobseekers_", '')
                b = b.replace("_", ' ')
                a.push(capitalize(b))
              })

              return MySwal.fire({
                title:"Check your biodata or CV before apply this job",
                html:<p className="alert alert-warning"> Sorry! Your data is not complete! Please complete your biodata/upload latest resume/work experiences/educations/skill and languages </p>
              })
            }
          },
          onOpen: () => {
            // `MySwal` is a subclass of `Swal`
            //   with all the same instance & static methods
            //MySwal.clickConfirm()
          }
        }).then((obj) => {
          if( has(obj, 'dismiss') ) {
            return
          }

          if( has(obj, 'value') && obj.value == '') {
            return MySwal.fire({
              title:"Please Enter message",
              text:"This is important section, please Type a message or reason "
            })
          }
          
          MySwal.fire({
            title: 'Now applying...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            onOpen: () => {
              MySwal.showLoading();
              applyJob({
                job_id:this.state.item.job_id,
                description: obj.value
              }).then(res=>{
                  MySwal.fire(
                    'Great!',
                    'This job has been applied. Please check out in your dasboard',
                    'success'
                  )
                  setTimeout(()=>{
                    navigate('/user/dashboard')
                  }, 4000)
              }).catch(err=>{
                MySwal.fire(
                  'Sorry!',
                  has(err.response.data, 'messages') ? err.response.data.messages : 'Something unknown error!',
                  'error'
                )
              })
            }
          })
          
        })
      } else {
        MySwal.fire({
          title: 'Please login first...',
          text:'Please wait! it will auto redirect to login page in any seconds',
          timer: 2000,
          onBeforeOpen: () => {
            MySwal.showLoading()
          },
          onClose: () => {
            navigate('/login')
          }
        })
      }
      
    }

    checkBiodata(user) {
      return reject(makeArrayKeyLabel(user), (d)=>{
        return (d.label != null && d.label != '' && d.label !== 0 )
      })
    }

    render() {
      const { location, auth } = this.props
      const { item } = this.state
      
      if( this.state.loaded && this.state.error != '' ) {
        return <Layout>
            <SEO title="Job Detail" />
            <h1>Not found</h1>
            <h5>{this.state.error}</h5>
        </Layout>
      }

      if( !item && this.state.loading ) {
        return <Layout>
            <SEO title="Job Detail" />
            <ContentDetailLoader />
        </Layout>
      }

      if( item ) {
        return (<Layout>
          <UserContext.Consumer>
            {(cuser => 
            <>
            <SEO title={item.job_title} description={`${item.job_title} From ${item.employers_company_name} - ${item.job_type} - ${item.peropinsi.lokasi_nama}`} />
            <div className="container" style={{ marginBottom: `1.45rem` }}>
                <nav aria-label="breadcrumb mb-3">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                    <li className="breadcrumb-item"><Link to={'/job'}>Jobs</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{item.job_title}</li>
                  </ol>
                </nav>

                <div className="company-box row">
                    <div className="comp-logo col-12 col-lg-2 text-center">
                      <div className="d-flex align-items-center justify-content-center text-center" style={{
                        width:160,
                        height:160,
                        margin:'0 auto'
                      }}>
                        {item && <img className="align-middle mb-0" src={item.employers_logo} alt={item.job_title} style={{maxWidth:158}}/>}
                      </div>
                    </div>
                    <div className="comp-sum col-12 col-lg-10">
                      <div className={classnames({
                        "border-left pl-3":this.state.windowWidth > this.mediaQuery.phone
                      })}>
                        <h1 className="display-4 mb-0">{item.job_title}</h1>
                        <p className="lead mb-2" style={{color:'#0F1698'}}>{item.employers_company_name}</p>
                        <p className="mb-1 text-dark"> <FaMapMarker size={12} /> {item.peropinsi.lokasi_nama} </p>
                        <h5 className="mb-2"><span className="badge badge-primary">{item.job_type}</span></h5>
                      </div>
                        
                    </div>
                </div>

                <div className="row mt-5">              
                  <div className="col-12">
                    <div className="mb-0" style={{padding:25, background:"#e9ecef"}}>
                        
                        <div className="row">
                          <div className="col-12 col-lg-4">

                            {item.industry_type_label != '' && <p className="mb-2">
                                Industry Type : <span className="font-weight-bold">{item.industry_type_label}</span>
                            </p>}
                            {item.category && <p className="mb-2">
                                Category : <span className="font-weight-bold">{capitalize(item.category.category)}</span>
                            </p>}

                            {item.job_type && <p className="mb-2">
                                Job Type : <span className="font-weight-bold">{item.job_type}</span>
                            </p>}

                            {item.salary_payment_obj && <p className="mb-2">
                              Salary Type : <span className="font-weight-bold">{item.salary_payment_obj.salary_payment}</span>
                            </p>}
                          
                            <p className="mb-2">Salary Nominal/Range : 
                              {item.salary_nominal && <span className="font-weight-bold">{item.salary_nominal}</span>}
                            </p>
                          </div>

                          <div className="col-12 col-lg-5">
                            {item.office_address && <p className="mb-2">Office Address : 
                              <span className="font-weight-bold"> {item.office_address}</span>
                            </p>}

                            {item.peropinsi && <p className="mb-2">Work location :
                              <span className="font-weight-bold"> {item.peropinsi.lokasi_nama}</span>
                            </p>}

                            {item.negara && <p className="mb-2">Country : 
                              <span className="font-weight-bold"> {item.negara.country}</span>
                            </p>}

                            {item.job_start_date && <p className="mb-2">Published date : 
                              <span className="font-weight-bold"> {displayHumanDate(item.job_start_date)}</span>
                            </p>}

                            {item.job_finish_date && <p className="mb-2">Deadline date : 
                              <span className="font-weight-bold"> {displayDate(item.job_finish_date)}</span>
                            </p>}

                          </div>

                          <div className="col-12 col-lg-3 border-left">
                            {item.is_closed && <div className="mt-3 align-items-center text-center" style={{height:150}}>
                                <div className="alert alert-warning">
                                    The job vacancy was closed because it was out of deadline date
                                </div>
                            </div>}

                            {(item.is_published || item.is_next) && <div className="mt-3 align-items-center text-center" style={{height:150}}>
                              <button className="btn btn-danger btn-block align-middle btn-md shadow" type="button" onClick={this.onJustApply.bind(this, auth.user)}> Quick Apply</button> <br/>

                              <button className="btn btn-outline-danger btn-block align-middle btn-md shadow" type="button" onClick={this.onApply.bind(this, auth.user)}>Apply with Message</button>
                            </div>}
                          </div>
                        </div>
                    
                    </div>
                  </div>
                </div>

                <div className="row mt-0">
                    <div className="col-12">
                        <div className="banner-premium-job-detail">
                          <div className="row justify-content-end">
                            <div className="col-lg-5 col-sm-12">
                              <p className="mt-2 mb-3" style={{textAlign:"right", color:'#fff'}}>
                                Kini Kami hadir dengan sebuah SOLUSI menemukan lowongan kerja lebih mudah dan lebih dekat dengan HRD Perusahaan dengan JOBSMIGAS PREMIUM
                              </p>
                            </div>
                            <div className="col-lg-2 col-sm-12">
                              <Link to={'/premium'} className="btn mt-3 btn-dark btn-lg">Pelajari</Link>
                            </div>
                          </div>
                          
                        </div>
                    </div>
                </div>

                {item.job_detail && <div className="row mt-5">
                  <div className="col-12">
                        <h5 className="">Job Description</h5>
                        <div className="">
                          {item.job_detail.split('\n').map((i, key) => {
                            return <span key={key}>
                              <span dangerouslySetInnerHTML={{
                                __html: i
                              }} />
                            <br/></span>
                            
                          })}
                        </div>
                  </div>
                </div>}

                {item.job_requirment && <div className="row mt-5">
                  <div className="col-12">
                        <h5 className="">Requirements</h5>
                        <div className="">
                          {item.job_requirment.split('\n').map((i, key) => {
                            return <span key={key}>
                              <span dangerouslySetInnerHTML={{
                                __html: i
                              }} />
                              <br/>
                            </span>
                          })}
                        </div>
                  </div>
                </div>}

                <div className="row">
                    <div className="col-12">
                      <div className="card bg-light mb-2">
                        <div className="card-body">
                          <h5>About {item.employers_company_name}</h5>
                          {this.state.more ? <div className="card-text" style={{fontSize:14}}>
                          {item.employers_description.split('\n').map((item, key) => {
                              return <span key={key}>
                                <span dangerouslySetInnerHTML={{
                                  __html: item
                                }}/>
                                <br/>
                              </span>
                          })}
                          <br/>
                          <a href="#" className="stretched-link" onClick={(e)=>{ 
                              e.preventDefault()
                              this.setState({more:!this.state.more})
                          }}>Less</a>
                          </div> : <div className="card-text" style={{fontSize:15}}>
                          {truncate(item.employers_description, {length:560}).split('\n').map((item, key) => {
                              return <span key={key}>
                                <span dangerouslySetInnerHTML={{
                                  __html: item
                                }}/>
                                <br/>
                              </span>
                          })}
                          <br/>
                          <a href="#" className="stretched-link" onClick={(e)=>{ 
                              e.preventDefault()
                              this.setState({more:!this.state.more})
                          }}>More</a>
                          </div>}
                      </div>
                      </div>
                    </div>
                </div>

            </div>
            </>)}
          </UserContext.Consumer>
        </Layout>)
      }

      return null
    }
  }

  
const mapStateToProps = state => ({
  auth:state.auth
})

export default connect(mapStateToProps, {})(JobDetail)