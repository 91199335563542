import React from 'react';
import JobList from "./list"
import JobDetail from "./detail"
import { has } from "lodash"

const JobIndex = ({location, pageContext, history}) => {
    if( has(pageContext, 'isDetail') && pageContext.isDetail ) {
        return (<JobDetail history={history} location={location} />)
    } else {
        return (<JobList location={location} />)
    }   
}
   

export default JobIndex